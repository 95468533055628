import { Injectable } from "@angular/core";
import { Collection } from "../models/collection";

import { HttpClient } from "@angular/common/http";
import { CollectionService } from "./collection.service";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { SellerActivity } from "../models/activities.model";

@Injectable({
  providedIn: "root",
})
export class ActivitiesService {
  public unreadSellerActivities: BehaviorSubject<number>;

  constructor(
    private http: HttpClient,
    private collectionService: CollectionService
  ) {
    this.unreadSellerActivities = new BehaviorSubject<number>(0);
  }

  get unreadSellerActivitiesValue(): number {
    return this.unreadSellerActivities.value;
  }

  set unreadSellerActivitiesValue(count: number) {
    this.unreadSellerActivities.next(count);
  }

  /**
   * Get Seller Activity
   */
  getSellersActivity(
    params: any
  ): Observable<Collection<SellerActivity>> {
    return this.http
      .get<Collection<SellerActivity>>("/activities/sellers", {
        params,
      })
      .pipe(
        map((activity) => {
          activity.data.forEach((item) => {
            item.created_at = new Date(item.created_at);
          });
          return activity;
        })
      );
  }

  /**
   * Get Seller Unread Activity
   */
  getSellersUnreadActivity(
    params: any
  ): Observable<Collection<SellerActivity>> {
    return this.http
      .get<Collection<SellerActivity>>("/activities/sellers/unread", {
        params,
      })
      .pipe(
        map((activity) => {
          activity.data.forEach((item) => {
            item.created_at = new Date(item.created_at);
          });
          return activity;
        })
      );
  }

  /**
   * Change Seller Activity is_read
   */
  setSellersActivityRead(id: number) {
    return this.http.get(`/activities/sellers/set-read/${id}`).pipe(
      tap(() => {
        return this.unreadSellerActivities.next(
          this.unreadSellerActivities.value - 1
        );
      })
    );
  }

  /**
   * Get Seller Activity is_read count
   */
  getSellersUnreadActivitiesCount() {
    return this.http.get("/activities/sellers/unread-count").pipe(
      tap((count: number) => {
        this.unreadSellerActivities.next(count);
      })
    );
  }
}
