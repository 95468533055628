import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Option } from '../../../models/option';
import { FormService } from '../../../services/form.service';
import { OrderShipping } from '../../../models/order-shipping';
import { OrderService } from '../../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-driver-dialog',
    templateUrl: './add-driver-dialog.component.html',
})
export class AddDriverDialogComponent implements OnInit {
    /**
     * Order shipping ID
     */
    @Input()
    public orderShippingId: number;

    /**
     * Form group of the entity with its fields
     */
    public formGroup: FormGroup;

    constructor(private orderService: OrderService, private alertService: ToastrService, private modalService: NgbModal, private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this._initForm();
    }

    /**
     * Check if the specified control has any errors in the provided validator or not
     */
    controlHasError(validator: string, controlName: string) {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validator) && (control.dirty || control.touched);
    }

    /**
     * Check if the specified control is invalid and has any errors or not
     */
    isControlInvalid(controlName: string) {
        const control = this.formGroup.get(controlName);
        return control.invalid && (control.dirty || control.touched);
    }

    /**
     * Submit form method to send data to api
     */
    onSubmit() {
        const formData = this.formGroup.value as OrderShipping;

        this.orderService.addDriverInfo(this.orderShippingId, formData)
            .subscribe(() => {
                this.alertService.success('اطلاعات شما با موفقیت ذخیره شد');
            }, () => {
                this.alertService.error('مشکلی در ذخیره اطلاعات به وجود آمد');
            });
    }

    /**
     * Initialize the category form group
     */
    private _initForm(): void {
        this.formGroup = this.formBuilder.group({
            driver_name: ['', [
                Validators.required,
            ]],
            driver_mobile: ['', [
                Validators.required,
            ]],
        });
    }

}
