import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
    enabled: false,
    dsn: 'https://b2b26237797c4982968ecd94289b6874@sentry.sepantatech.com/2',
    release: 'admin-panel@1.0.0-alpha',
    environment: environment.production ? 'prod' : 'dev',
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', environment.apiUrl],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
