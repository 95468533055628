import { Injectable } from "@angular/core";
import { Collection, CollectionColumn } from "../models/collection";
import { HttpClient } from "@angular/common/http";
import { CollectionService } from "./collection.service";
import { Observable } from "rxjs";
import { Order, OrderReport } from "../models/order";
import { map } from "rxjs/operators";
import { OrderShipping } from "../models/order-shipping";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  /**
   * List of available columns for this entity
   */
  static AVAILABLE_FIELDS: CollectionColumn[] = [];

  /**
   * Map status values to translated values
   * 'pending_payment', 'processing', 'sent', 'delivered', 'completed'
   */
  public static readonly STATUS_MAP = {
    pending_payment: "در انتظار پرداخت",
    paid: "پرداخت شده",
    processing: "در حال پردازش",
    canceled: "لغو شده",
    sent: "ارسال شده",
    delivered: "تحویل داده شده",
    completed: "تکمیل شده",
    expired: "منقضی شده",
    approved: "تحویل داده شده (تایید مشتری)",
    checkout: "درخواست تسویه حساب",
    done: "تسویه حساب شده",
  };

  public static readonly STATUS_COLOR = {
    pending_payment: "warning",
    processing: "warning",
    canceled: "danger",
    sent: "success",
    paid: "success",
    expired: "danger",
    delivered: "success",
    approved: "success",
    completed: "success",
    done: "success",
    checkout: "success",
  };

  constructor(
    private http: HttpClient,
    private collectionService: CollectionService
  ) {}

  /**
   * Fetch the list of all orders with pagination, filter and sort
   */
  getAll(
    page: number,
    sortBy: string = "",
    perPage: number = 15,
    searchInfo: any[] = [],
    filterBy: object = {}
  ): Observable<Collection<Order>> {
    const params = this.collectionService.generateParams(
      page,
      sortBy,
      perPage,
      searchInfo,
      filterBy
    );

    return this.http
      .get<Collection<Order>>("/v4/orders", {
        params,
      })
      .pipe(
        map((order) => {
          order.data.forEach((item) => {
            item.created_at = new Date(item.created_at);
            item.updated_at = new Date(item.updated_at);
          });
          return order;
        })
      );
  }

  /**
   * Get the order by specified id
   */
  getById(id: number): Observable<Order> {
    return this.http.get<Order>("/v4/orders/" + id).pipe(
      map((order) => {
        order.created_at = new Date(order.created_at);
        order.updated_at = new Date(order.updated_at);
        return order;
      })
    );
  }

  /**
   * Create a new order
   */
  create(order: Order): Observable<Order> {
    return this.http.post<Order>("/orders", order);
  }

  /**
   * Update the specified order
   */
  update(id: number, order: Order): Observable<Order> {
    return this.http.put<Order>("/orders/" + id, order);
  }

  /**
   * Update the specified order
   */
  updateStatus(id: number, info: any): Observable<Order> {
    return this.http.post<Order>("/orders/status/" + id, info);
  }

  /**
   * Add Driver Info for Order
   */
  addDriverInfo(
    orderShippingId: number,
    data: OrderShipping
  ): Observable<object> {
    return this.http.post<object>(
      "/orders/driver-info/" + orderShippingId,
      data
    );
  }

  /**
   * Get Seller Order Dones
   */
  getSellerOrderDone(
    seller_id: number,
    params: any
  ): Observable<OrderReport[]> {
    return this.http.get<OrderReport[]>(
      `/sellers/${seller_id}/reports/orders/done`,
      {
        params,
      }
    );
  }

  /**
   * Download the excel file for all customers in the system
   */
  getSellerOrderDoneExportExcelFile(seller_id: number, params: any): void {
    this.http
      .get(`/sellers/${seller_id}/reports/orders/done/excel`, {
        params,
        responseType: "blob",
      })
      .subscribe((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  /**
   * Fetch the list of all seller bank accounts with pagination, filter and sort
   */
  deleteOrderItem(order_id: number, item_id: number): Observable<Order[]> {
    return this.http.delete<Order[]>(`/orders/${order_id}/remove/${item_id}`);
  }
}
