import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthResponse } from "../models/auth-response";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LoginForm } from "../models/login-form";
import { Admin } from "../models/admin";
import { AbstractControl, ValidationErrors } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  public currentUser$: Observable<Admin>;
  public currentUserSubject: BehaviorSubject<Admin>;

  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  public static passwordConfirmRule(
    matchTo: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { confirmPassword: true };
    };
  }

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Admin>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): Admin {
    return this.currentUserSubject.value;
  }

  set currentUserValue(admin: Admin) {
    this.currentUserSubject.next(admin);
  }

  /**
   * Send admin login request to the api
   */
  login(credentials: LoginForm): Observable<any> {
    return this.http.post<AuthResponse>("/auth/login", credentials).pipe(
      map((auth: AuthResponse) => {
        return this.setAuthToken(auth.token.access_token);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return err;
      })
    );
  }

  /**
   * Send admin google login request to the api
   */
  googleLogin(credentials: { auth_code: string }): Observable<any> {
    return this.http
      .post<AuthResponse>("/v4/auth/google-login", credentials)
      .pipe(
        map((auth: AuthResponse) => {
          return this.setAuthToken(auth.token.access_token);
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          return err;
        })
      );
  }

  hasPermission(permission: string): boolean {
    return (
      this.currentUserValue.permissions.findIndex((val) => {
        // tslint:disable-next-line:triple-equals
        return val == permission;
      }) !== -1
    );
  }

  logout(redirectUrl?: string) {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(["/auth/login"], {
      queryParams: { returnUrl: redirectUrl ?? this.router.url },
    });
  }

  getUserByToken(): Observable<Admin> {
    const auth = this.getAuthToken();
    if (!auth) {
      return of(undefined);
    }

    return this.http.get<Admin>("/auth/info").pipe(
      map((admin: Admin) => {
        this.currentUserSubject = new BehaviorSubject<Admin>(admin);
        return admin;
      })
    );
  }

  thridPartyLogin(type: string, id: number): Observable<any> {
    return this.http.get<any>(`/${type}/${id}/token`).pipe(
      map((auth: any) => {
        return auth;
      }),
      catchError((err) => {
        return of(undefined);
      })
    );
  }

  /**
   * @inheritDoc
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  /**
   * Set the admin login token in the local storage
   */
  setAuthToken(token: string): void {
    localStorage.setItem(this.authLocalStorageToken, token);
  }

  /**
   * Read the stored admin login token from local storage
   */
  getAuthToken(): string {
    return localStorage.getItem(this.authLocalStorageToken);
  }

  removeAuthToken() {
    localStorage.setItem(this.authLocalStorageToken, null);
  }
}
