
export const environment = {
  production: false,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://admin.bazaarplaza.com/api/admin',
  lightIcon: 'https://storage.bazaarplaza.com/logo/logo_bazaarplaza.png',
  darkIcon: 'https://storage.bazaarplaza.com/logo/bp.jpg',
  siteName: 'BazaarPlaza',
  showFooter: 'false',
  storageURL: 'https://storage.bazaarplaza.com',
  recaptchaSiteKey: '6Le8UcQpAAAAANz571WeE0_t5gInF30OzbCn_jti',
  shopLink: 'https://bazaarplaza.com',
  sellerLink: 'https://seller.bazaarplaza.com',
  contactUsLink: 'https://bazaarplaza.com/contact',
  favicon: 'https://storage.bazaarplaza.com/logo/logo_bazaarplaza.png',
  googleLogin: '350798170152-06ulk6g4c4n8cgab40i0sbu2dlsjqj8h.apps.googleusercontent.com'
};
