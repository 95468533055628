<form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="form form-label-right m-5">
    <div class="mt-5">
        <div class="form-group row">
            <div class="col-lg-12">
                <label>نام راننده</label>
                <input
                        [class.is-invalid]="isControlInvalid('driver_name')"

                        autocomplete="off"
                        class="form-control form-control-lg form-control-solid"
                        formControlName="driver_name"
                        name="driver_name"
                        type="text"
                />
                <div *ngIf="controlHasError('required', 'driver_name')" class="invalid-feedback">
                    نام راننده را وارد نمایید
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-12">
                <label>موبایل راننده</label>
                <input
                        [class.is-invalid]="isControlInvalid('driver_mobile')"

                        autocomplete="off"
                        class="form-control form-control-lg form-control-solid"
                        formControlName="driver_mobile"
                        name="driver_mobile"
                        type="text"
                />
                <div *ngIf="controlHasError('required', 'driver_mobile')" class="invalid-feedback">
                    موبایل راننده را وارد نمایید
                </div>
            </div>
        </div>

        <div class="float-right">
            <button [disabled]="formGroup.invalid" class="btn btn-primary font-weight-bold mr-2"
                    type="submit">
                دخیره
            </button>
        </div>
    </div>

</form>
