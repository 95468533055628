import { Injectable } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CollectionService {
  /**
   * Generate the base url for the services methods that works with data tables
   */
  generateUrl(
    url: string,
    page: number,
    filterBy: object,
    sortBy: string,
    perPage: number,
    searchInfo: any[] = []
  ): string {
    let baseUrl = url + "?";
    baseUrl += "per_page=" + perPage + "&";

    if (sortBy) {
      baseUrl += "&sort_by=" + sortBy + "&";
    }

    if (searchInfo && searchInfo.length) {
      console.log("stringified ", JSON.stringify(searchInfo));

      baseUrl += `searchOnColumns=${JSON.stringify(searchInfo)}&`;
    }

    if (filterBy) {
      baseUrl += CollectionService._createQuery(filterBy);
    }

    baseUrl += "page=" + page;

    return baseUrl;
  }

  generateParams(
    page: number,
    sortBy: string,
    perPage: number,
    searchInfo: any[],
    filterBy: object = {},
    logic: string = ""
  ) {
    let params = new HttpParams()
      .append("page", `${page}`)
      .append("per_page", `${perPage}`)
      .append("searchOnColumns", JSON.stringify(searchInfo));

    for (const val in filterBy) {
      if (val) {
        params = params.append(`filter[${val}]`, `${filterBy[val]}`);
      }
    }

    if (logic) {
      params = params.append('logic' , logic)
    }

    if (sortBy) {
      let sort_by = "";
      let sort_order = "";
      if (sortBy.includes("-")) {
        sort_by = sortBy.replace("-", "");
        sort_order = "desc";
      } else {
        sort_by = sortBy;
        sort_order = "asc";
      }

      params = params.append("sort_by", sort_by);
      params = params.append("sort_order", sort_order);
    }

    console.log({ params });

    return params;
  }

  getSortBy(sortData: MatSort) {
    let sortBy = "";

    if (sortData.direction === "desc") {
      sortBy = "-";
    }

    if (sortData.active) {
      sortBy += sortData.active;
    }

    return sortBy;
  }

  private static _createQuery(filterData) {
    let output = "";
    for (const val in filterData) {
      output += "filter[" + val + "]=" + filterData[val] + "&";
    }
    return output;
  }
}
