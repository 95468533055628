import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./services/auth.service";
import { SplashScreenModule } from "./components/partials/layout/splash-screen/splash-screen.module";
import { ApiInterceptor } from "./utils/api.interceptor";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatPaginatorIntlFa } from "./utils/mat-paginator-intl-fa";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MaterialPersianDateAdapter,
  PERSIAN_DATE_FORMATS,
} from "./utils/material-persian-date-adapter";
import { ToastrModule } from "ngx-toastr";
import { AddDriverDialogComponent } from "./components/shared/add-driver-dialog/add-driver-dialog.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ActivitiesService } from "./services/activities.service";
import { NotificationsService } from "./services/notifications.service";


function appInitializer(
  authService: AuthService,
  activitiesService: ActivitiesService,
  notificationsService: NotificationsService
) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
      activitiesService.getSellersUnreadActivitiesCount().subscribe(() => {
        resolve(true);
      });
      notificationsService.getUnreadCount().subscribe(() => {
        resolve(true);
      });
    });
  };
}

@NgModule({
  declarations: [AddDriverDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, ActivitiesService, NotificationsService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlFa,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MaterialPersianDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: PERSIAN_DATE_FORMATS,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
