import { Injectable } from "@angular/core";
import { Collection } from "../models/collection";

import { HttpClient } from "@angular/common/http";
import { CollectionService } from "./collection.service";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { NotificationList } from "../models/notification";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public unreadNotifications: BehaviorSubject<number>;

  constructor(
    private http: HttpClient,
    private collectionService: CollectionService
  ) {
    this.unreadNotifications = new BehaviorSubject<number>(0);
  }

  get unreadNotificationsValue(): number {
    return this.unreadNotifications.value;
  }

  set unreadNotificationsValue(count: number) {
    this.unreadNotifications.next(count);
  }

  /**
   * Get Notification
   */
  getNotifications(
    params: any
  ): Observable<Collection<NotificationList>> {
    return this.http
      .get<Collection<NotificationList>>("/profile/notifications", {
        params,
      })
      .pipe(
        map((list) => {
          list.data.forEach((item) => {
            item.created_at = new Date(item.created_at);
          });
          return list;
        })
      );
  }

  /**
   * Get Unread Notifications
   */
  getUnreadNotifications(
    params: any
  ): Observable<Collection<NotificationList>> {
    return this.http
      .get<Collection<NotificationList>>("/profile/notifications/unread", {
        params,
      })
      .pipe(
        map((list) => {
          list.data.forEach((item) => {
            item.created_at = new Date(item.created_at);
          });
          return list;
        })
      );
  }

  /**
   * Change Seller Activity is_read
   */
  setNotificationRead(id: number) {
    return this.http
      .post(`/profile/notifications/set-read`, {
        ids: [id],
      })
      .pipe(
        tap(() => {
          return this.unreadNotifications.next(
            this.unreadNotifications.value - 1
          );
        })
      );
  }

  /**
   * Get Unread Count
   */
  getUnreadCount() {
    return this.http.get("/profile/notifications/unread-count").pipe(
      tap((count: number) => {
        this.unreadNotifications.next(count);
      })
    );
  }

  /**
   * Get Notification Config
   */
  getNotificationsConfig() {
    return this.http.get("/profile/notifications/config");
  }

  /**
   * Update Notification Config
   */
  updateNotificationsConfig(payload: any): Observable<any> {
    return this.http.post<any>("/profile/notifications/config", payload);
  }
}
