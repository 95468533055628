import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: ToastrService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.authService.getAuthToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      url: environment.apiUrl + req.url,
      headers: authToken
        ? req.headers.set("Authorization", "Bearer " + authToken)
        : req.headers,
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Check if the response has a body with a `result` field.
          if (event.body && event.body.result !== undefined) {
            // Return a new HttpResponse object with the data.result
            return event.clone({ body: event.body.result });
          }
        }
        return event;
      }),
      catchError((error) => {
        let message: any = error.error.message;

        if (error.status === 500) {
          this.alertService.error(message);
        } else {
          if (typeof message === 'string') {
            this.alertService.error(message);
          } else {
            for (const [key, value] of Object.entries(message)) {
              let val: any = value
              this.alertService.error(val);
            }
          }
        }
        if (error.status === 401) {
          this.authService.removeAuthToken();
          this.router.navigate(["/auth/login"]);
          return throwError(error);
        }

        return throwError(error);
      })
    );
  }
}
